<template>
	<b-card-group deck>
		<b-card>
			<b-row>
				<b-col>
					<h4 class="mb-1">
						{{ translate('new_address') }}
					</h4>
				</b-col>
			</b-row>
			<hr class="mt-2 mb-4">
			<b-row>
				<b-col>
					<address-country-form
						:address="ADDRESSES.shipping"
						:user-country="SHOW_USER_COUNTRY"
						:form-import="form"
						:display-override-toggle="showOverrideValidation"
						:errors-import="errors.errors"
						@on-submit="newAddress" />
				</b-col>
			</b-row>
		</b-card>
	</b-card-group>
</template>
<script>

import AddressCountryForm from '@/components/AddressCountryForm';
import { Profile, Validations, Addresses } from '@/translations';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import { SHOW_USER_COUNTRY } from '@/settings/Country';
import { SYSTEM_ALERT, UNPROCESSABLE } from '@/settings/Errors';
import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import EventBus from '@/util/eventBus';
import { ADDRESSES } from '@/settings/AddressBook';

export default {
	name: 'NewAddress',
	messages: [Profile, Validations, Addresses],
	components: { AddressCountryForm },
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				city: '',
				postcode: '',
				country_code: '',
				region_id: '',
				override_validation: false,
			},
			addressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			country: new Country(),
			states: new Country(),
			alert: new this.$Alert(),
			SHOW_USER_COUNTRY,
			showOverrideValidation: false,
			addressVerificationCountries,
			ADDRESSES,
		};
	},
	computed: {
		errors() {
			return this.addressBook.data.errors;
		},
	},
	methods: {
		newAddress(form) {
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'));

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.addressBook.saveAddress(this.$user.details().id, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });
				this.$router.push({ name: 'AddressBook' });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.errors.status)) {
					Object.keys(this.errors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								const options = {
									config: {
										icon: 'error',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.newAddress(this.form);
								}).catch(() => {});
							}).catch(() => {});
						} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
	},
};
</script>
